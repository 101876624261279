import { render, staticRenderFns } from "./SectionBuilding.vue?vue&type=template&id=b27394d8&"
import script from "./SectionBuilding.vue?vue&type=script&lang=js&"
export * from "./SectionBuilding.vue?vue&type=script&lang=js&"
import style0 from "./SectionBuilding.vue?vue&type=style&index=0&id=b27394d8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectOrganismBuildingErrorOccurred: require('/app/components/organisms/project/OrganismBuildingErrorOccurred.vue').default,OrganismsProjectOrganismBuildingLoader: require('/app/components/organisms/project/OrganismBuildingLoader.vue').default,OrganismsProjectBuildingOrganismBuilding: require('/app/components/organisms/project/building/OrganismBuilding.vue').default,OrganismsProjectBuildingOrganismSidebarBuilding: require('/app/components/organisms/project/building/OrganismSidebarBuilding.vue').default,OrganismsProjectBuildingOrganismCartSpacesBuilding: require('/app/components/organisms/project/building/OrganismCartSpacesBuilding.vue').default,OrganismsProjectBuildingOrganismSelectedSpaceMobile: require('/app/components/organisms/project/building/OrganismSelectedSpaceMobile.vue').default,OrganismsProjectBuildingOrganismSpace: require('/app/components/organisms/project/building/OrganismSpace.vue').default,MoleculesCommonMoleculeFiltersPins3D: require('/app/components/molecules/common/MoleculeFiltersPins3D.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
